/*-----------------------------------------------------------------------------
 * @package;    Kwaeri User Experience - Library
 * @author:     Richard B Winters
 * @copyright:  2015-2018 Massively Modified, Inc.
 * @license:    Apache-2.0
 * @version:    0.1.13
 *---------------------------------------------------------------------------*/
'use strict'

// Import the Material Design Web components
import * as mdc from 'material-components-web';

// Import the Kwaeri User Experience Library: components
import * as kuxl from '../dist/js/src/kux';
import { kwdt } from '@kwaeri/web-developer-tools';

// Instantiate the Kwaeri Web Developer Tools & Kwaeri User Experience Library
let _ = new kwdt(),
    kux = new kuxl.kux();

// Ensure Material Design and Kwaeri User Experience Components are available in the window instance
window.mdc  = mdc;
window._ = _;
window.kux  = kux;
window.dragPositions = { lastX: null, lastY: null };
window.ticking = false;

// Call kux.controls() to instantiate all the UX Goodies
kux.controls();

// The monster is born!
Prism.plugins.toolbar.registerButton
(
    'select-code',
    function( env )
    {
        var button = document.createElement( 'button' );
        button.innerHTML = 'Select & Copy Code';

        button.addEventListener
        (
            'click',
            function()
            {
                // Source: http://stackoverflow.com/a/11128179/2757940
                if( document.body.createTextRange )
                { // ms
                    var range = document.body.createTextRange();
                    range.moveToElementText( env.element );
                    range.select();
                }
                else if( window.getSelection )
                { // moz, opera, webkit
                    var selection = window.getSelection();
                    var range = document.createRange();
                    range.selectNodeContents( env.element );
                    selection.removeAllRanges();
                    selection.addRange( range );
                }

                document.execCommand("copy");
            }
        );

	    return button;
    }
);
