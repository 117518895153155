/*-----------------------------------------------------------------------------
 * @package:    Kwaeri User Experience
 * @author:     Richard B Winters
 * @copyright:  2015-2018 Massively Modified, Inc.
 * @license:    Apache-2.0
 * @version:    0.1.13
 *---------------------------------------------------------------------------*/
// INCLUDES
/* Markdown support */
import showdown from 'showdown';
/* Chart Support */
import chart from 'chart.js';
/* Smooth scroll support */
import smoothscroll from 'smoothscroll-polyfill';
/* Material Design stuffs */
import { MDCList } from '@material/list';
import { MDCDrawer } from '@material/drawer';
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCTextField } from '@material/textfield';
import { MDCFloatingLabel } from '@material/floating-label';
import { MDCFormField } from '@material/form-field';
import { MDCRipple } from '@material/ripple';
import { MDCLineRipple } from '@material/line-ripple';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCIconButtonToggle } from '@material/icon-button';
import { MDCRadio } from '@material/radio';
import { MDCCheckbox } from '@material/checkbox';
import { MDCSelect } from '@material/select';
import { MDCSlider } from '@material/slider';
import autoinit from '@material/auto-init';
/* kwaeri stuffs */
import { kwdt } from "@kwaeri/web-developer-tools";
// GLOBALS
/* Initialize the smooth scroll polyfill: */
smoothscroll.polyfill();
/* Initialize autoinit support from MDC: */
autoinit();
/* Initialize kwdt: */
let _ = new kwdt();
//drawer = null,
//topAppBar = null;
let drawer = null, topAppBar = null;
/* Pack showdown's markdown converter into kwdt: */
_.markdown = new showdown.Converter({
    disableForced4SpacesIndentedSublists: true,
    tasklists: true,
    tables: true,
    ghMentions: true,
});
/**
 * Kwaeri UX
 *
 * Kwaeri User Experience is a collection of methods designed to ease the development
 * of a user interface. Ccollectively, these methods  provide a clean, and modern
 * approach, enabling a state of the art user experience.
 */
export class kux {
    /**
     * Class constructor
     *
     * @since 0.1.0
     */
    constructor() {
        /**
         * An object for storing event handlers
         *
         * @var object
         */
        this.eventHandlers = {};
    }
    /**
     * The Request method makes use of the _ to provide a standardapproach
     * to ajax interfacing.  The implementation is non-obtrusive.
     *
     *  [[Note]]:
     *  Any HTML element may be used that is typically leveraged for providing a link to the end user.
     *
     *  [[Example]]:
     *  <a href="relative/url/for/fallback" data-kwaeri="request" ...>Link Title</a>
     *
     *  The 'data-kwaeri' attribute tells kux that this element is meant to drive an ajax request.  The
     *  URL will be parsed from the HREF attribute before it is removed to disallow synchronous operation.
     *  The data-params attribute may be used as a compliement for elements which do not possess an href
     *  attribute, and even if so - in order to provide GET/POST data for the request, as well as for
     *  setting other flags.
     *
     * @var options object              Defines an associative array of parameters
     *      @param string path          Defines the path or url the request is sent to, Can be the base url (empty), or an alias (i.e. extensions/manage/<extension-name> )
     *      @param string interface     Defines the view requested
     *      @param string action        Reserved for specifying the controller method [action]
     *      @param string type          Defines how to handle/render the response (i.e. as a normal view, an extension view, lytebox view, etc)
     *      @param string method        Leave blank for GET, otherwise specify POST
     *      @param object data          An array/obj used to pass extra variables/data to get/post methods
     *      @param string contentType   An override for the jQuery.ajax() contentType parameter
     *      @param string dataType      An override for the jQuery.ajax() dataType parameter
     *      @param string mod           Used for leaving the scope of this framework
     *
     * @returns void
     *
     * @since 0.1.0
     */
    request(args) {
        let thisInstance = this;
        // As one might expect, we need to parse our options and progress though making our requestsb
        let options = _.extend({
            // Default values
            path: "",
            interface: "home",
            action: false,
            type: "normal",
            method: "GET",
            data: false,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'html',
            mod: false,
        }, args);
        // Prepare variables
        let url = null, params = null, resultSet = false, queryExists = false;
        // Ensure a trailing slash after path
        options.path += (options.path.slice(-1) === '/') ? '' : '/';
        // Ensure a non-empty interface
        if (options.interface === "") {
            options.type = (options.type === 'ext') ? 'dash' : 'home';
        }
        // If the controller method is not set, let's set it to the default
        if (!options.action || options.action === '0') {
            options.action = 'index';
        }
        switch (options.method) {
            case 'GET':
                {
                    url = options.path + options.interface + '/' + options.action;
                    if (options.mod && options.mod !== '0') {
                        url += '/' + options.mod;
                    }
                    if (options.data && options.data !== '0') {
                        if (!_.empty(options.data)) {
                            url += '?';
                            let dataCount = 0;
                            _.each(options.data, function (key, value) {
                                url += ((dataCount) ? '&' : '') + key + '=' + value;
                                dataCount++;
                            });
                            if (options.data.resultSet === '1') {
                                resultSet = true;
                            }
                            queryExists = true;
                        }
                    }
                }
                break;
            case 'POST':
                {
                    url = options.path + options.interface + '/' + options.action;
                    if (options.mod && options.mod !== '0') {
                        url += '/' + options.mod;
                    }
                    if (options.data && options.data !== '0') {
                        if (options.data.type === "submitForm") {
                            let forms = options.data.formName.split(",");
                            params = {};
                            if (forms.length > 1) {
                                for (let i = 0; i < forms.length; i++) { // What are we doing here?  formname must be passed as an object prop...we ever use this case before? We changed it.
                                    let temp = this.form({ formName: forms[i] }); // WAS: _.form( forms[i] );
                                    _.extend(params, temp);
                                }
                            }
                            else { // Betting we only ever used this case...
                                params = this.form({ formName: options.data.formName });
                            }
                        }
                        else {
                            params = options.data;
                            // Do here what you will.
                        }
                        if (options.data.resultSet === '1') {
                            resultSet = true;
                        }
                    }
                }
                break;
        }
        url += (queryExists) ? '&layout=0' : '?layout=0';
        //console.log( 'The URL for the request made is: ' + url );
        _.ajax({
            xhr: function () {
                var xhr = new XMLHttpRequest();
                //Upload progress
                xhr.upload.addEventListener("progress", function (event) {
                    if (event.lengthComputable) {
                        var percentComplete = Math.round(((event.loaded / event.total) * 100));
                        // Do something with upload progress
                        let ajaxProgress = document.querySelector('[data-purpose="progress"]');
                        ajaxProgress.style.width = percentComplete + '%';
                        if (percentComplete == 100) {
                            _.addClass(ajaxProgress, 'finished');
                            _.queue()(500)(function () {
                                _.addClass(ajaxProgress, 'notransition');
                                ajaxProgress.style.width = '';
                                _.removeClass(ajaxProgress, 'finished');
                            })();
                        }
                    }
                }, false);
                //Download progress
                xhr.addEventListener("progress", function (event) {
                    if (event.lengthComputable) {
                        var percentComplete = Math.round(((event.loaded / event.total) * 100));
                        // Do something with upload progress
                        let ajaxProgress = document.querySelector('[data-purpose="progress"]');
                        ajaxProgress.style.width = percentComplete + '%';
                        if (percentComplete == 100) {
                            _.addClass(ajaxProgress, 'finished');
                            _.queue()(500)(function () {
                                _.addClass(ajaxProgress, 'notransition');
                                ajaxProgress.style.width = '';
                                _.removeClass(ajaxProgress, 'finished');
                            })();
                        }
                    }
                }, false);
                return xhr;
            },
            url: url,
            async: true,
            cache: false,
            type: options.method,
            contentType: options.contentType,
            dataType: options.dataType,
            data: {
                mdata: JSON.stringify(params)
            },
            beforeSend: function () {
                // Animate our spinner and destroy any active toolbars
                if (options.type !== 'ibox' && options.type !== 'lbox' && options.type !== 'overlay' && options.type !== 'progress') {
                    //document.getElementsByClassName( 'navbar-brand' )[0].innerHTML = "";
                    //document.getElementsByClassName( 'navbar-brand' )[0].innerHTML = '<div id="mmod-badge" class="icon-logo-badge-small"></div>';
                    //document.getElementsByClassName( 'icon-logo-badge-small ' )[0].className = "icon-logo-badge-small spin";
                    let spinner = document.getElementsByClassName('navbar_brand')[0];
                    spinner.outerHTML = '<div class="navbar_brand icon-logo-badge-small-dev spin"></div>';
                    //_.addClass( spinner, 'spin' );
                    // Clear the facility toolbar in case one is visable
                    thisInstance.toolbar('destroy', {});
                    // Clear the extension toolbar in case one is visible
                    if (kux.hasOwnProperty('loadExtensionToolbar')) {
                        this.loadExtensionToolbar('destroy');
                    }
                }
            },
            /**
             * Success can have (after data):
             *  - textStatus
             *  - xhr
             */
            success: function (status, data) {
                // This here set's our spinner for the last time, and allows it to stop
                //document.getElementsByClassName( 'navbar-brand' )[0].innerHTML = "";
                //document.getElementsByClassName( 'navbar-brand' )[0].innerHTML = '<div id="mmod-badge" class="icon-logo-badge-small"></div>';
                //document.getElementsByClassName( 'icon-logo-badge-small ' )[0].className = "icon-logo-badge-small spin-stop";
                let spinner = document.getElementsByClassName('navbar_brand')[0];
                spinner.outerHTML = '<div class="navbar_brand icon-logo-badge-small-dev spin-stop"></div>';
                // Reset our ajax loading progress bar
                let ajaxProgress = document.querySelector('[data-purpose="progress"]');
                _.removeClass(ajaxProgress, 'notransition');
                // This here determines if a sysmsg needs to be let loose, and is typically utilized when an extension
                // is performing an action and then forwarding the end user to another location.
                if (options.data && options.data.forward) {
                    let actionMessageForSys = (document.querySelector('[data-toggle="isys"] .xrm-msg').innerHTML = data).querySelector('[data-tattle="information"]').innerHTML;
                    document.querySelector('[data-toggle="isys"] .xrm-msg').innerHTML = "";
                    if (actionMessageForSys) {
                        // Here we should invoke $.kux.view() with the 'load' argument in order to show the system message
                        thisInstance.receive({
                            msg: actionMessageForSys
                        });
                    }
                    // It was requested to send the client elsewhere after the action has been performed...
                    if (_.type(options.data.forward) === 'object') {
                        if (_.type(options.data.forward.data) === 'object') {
                            thisInstance.request({
                                path: options.data.forward.path || options.path,
                                interface: options.data.forward.interface || options.interface,
                                action: options.data.forward.action || 'index',
                                type: options.data.forward.type || options.type,
                                data: options.data.forward.data || {}
                            });
                        }
                        else {
                            thisInstance.request({
                                path: options.data.forward.path || options.path,
                                interface: options.data.forward.interface || options.interface,
                                action: options.data.forward.action || 'index',
                                type: options.data.forward.type || options.type
                            });
                        }
                    }
                    else {
                        thisInstance.request({
                            path: options.path,
                            interface: options.interface,
                            action: options.data.forward || 'index',
                            type: options.type
                        });
                    }
                    return;
                }
                // If there was no forward request, let's load the content for the user
                thisInstance.receive({
                    content: data,
                    type: options.type,
                    path: options.path || false,
                    interface: options.ui,
                    action: options.action,
                    sysmsg: resultSet
                });
                // Set our controls after all is said and done
                thisInstance.controls();
            },
            error: function (xhr, textStatus, errorThrown) {
                thisInstance.receive({
                    content: textStatus + ": " + errorThrown,
                    type: options.type,
                    interface: options.interface
                });
            }
        });
    }
    /**
     * Method to render content to the display
     *
     * @var object  options     Defines an associative array of parameters.
     *      @param HTML     content     Defines the content string.
     *      @param string   type        Defines the type of content to render.
     *      @param string   view        Defines the view index for the mmodLoadTB() function.
     *      @param array    interface   Defines the layout of the display to load the toolbar for (if any).
     *      @param boolean  sysmsg      Defines whether to display a system message or not.  Defaults to false.
     *
     * @return void
     *
     * @since 0.1.0
     */
    receive(args) {
        var options = _.extend({
            // Default values
            content: "",
            type: "",
            interface: "",
            action: "",
            sysmsg: false
        }, args);
        // Parse the type of content to render
        switch (options.type) {
            case 'normal':
                {
                    // The toggleNav button sits inside of the div we're replacing the contents of...
                    //let replaceNavToggle =  '<p class="pull-left visible-xs">' +
                    //                            '<button type="button" class="btn btn-primary btn-xs" data-toggle="offcanvas">Toggle Nav</button>' +
                    //                        '</p>\r\n';
                    document.querySelector('[data-purpose="client-display"]').innerHTML = /*replaceNavToggle + */ options.content;
                    if (options.sysmsg === true) {
                        let message = document.querySelector('[data-tattle="information"]').innerHTML;
                        this.systemMessage({ message: message });
                    }
                }
                break;
            case 'facility':
                {
                    document.querySelector('[data-purpose="client-display-facility"]').innerHTML = options.content;
                    this.toolbar('load', { ui: options.ui, action: options.action });
                    if (options.sysmsg === true) {
                        var message = document.querySelector('[data-tattle="information"]').innerHTML;
                        this.systemMessage({ message: message });
                    }
                }
                break;
            case 'xrm-facility-toolbar':
                {
                    //document.querySelector( 'ul.xrm-facility-toolbar' ).innerHTML = "";
                    //document.querySelector( 'ul.xrm-facility-toolbar').appendChild( options.content );
                }
                break;
            case 'ext':
                {
                    document.querySelector('[data-purpose="]client-display-extension"').innerHTML = options.content;
                    // Extensions can provide optional toolbars, let's load them if found
                    if (options.path) {
                        // extract the extension name from the path
                        _.script({ file: 'menu.js', type: 'elements', system: 'ext', extension: 'mmod-cms', fwd: { ui: options.ui, action: options.action } });
                    }
                    this.toolbar('load', { interface: options.interface, action: options.action, system: 'ext' });
                    if (options.sysmsg === true) {
                        var message = document.querySelector('[data-tattle="information"]').innerHTML;
                        this.systemMessage({ message: message });
                    }
                }
                break;
            case 'xrm-extension-toolbar':
                {
                    //document.querySelector( 'ul.xrm-extension-toolbar' ).innerHTML = "";
                    //document.querySelector( 'ul.xrm-extension-toolbar').appendChild( options.content );
                }
                break;
            case 'overlay':
                {
                    document.querySelector('[data-purpose="kwaeri-lyte"]').innerHTML = options.content;
                    this.lytebox("load", {
                        overlay: 1
                    });
                }
                break;
            case 'lbox':
                {
                    //document.getElementById( 'kwaeri-lytebox' ).innerHTML = options.content;
                    document.querySelector('data-purpose="kwaeri-lytebox"] .content-block').innerHTML = options.content;
                    if (options.interface === 'pbar') {
                        this.lytebox("load", { interface: 'pbar' });
                    }
                    else {
                        this.lytebox("load", {});
                    }
                }
                break;
            case 'ibox':
                {
                    document.querySelector('data-purpose="xrm-lytebox"]').innerHTML = '<iframe id="xrm_lytebox_iframe" width="800px" height="600px" scrolling="auto" src="/?option=&tmpl=0"></iframe>';
                    this.lytebox("load", {
                        vscroll: 1,
                        interface: 'pbar'
                    });
                }
                break;
            case 'progress':
                {
                    document.querySelector('[data-toggle="isys"] .xrm-xmsg').innerHTML = options.content;
                }
                break;
        }
        /* Check for extension plug-ins and run methods if found
        if( $.kux.extensionToolbarItems && !$.isEmptyObject( $.kux.extensionToolbarItems ) )
        {
        }

        if( $.kux.facilityToolbarItems && !$.isEmptyObject( $.kux.facilityToolbarItems ) )
        {
        }
        // Check if the application provides a toolbar, and invoke methods if so
        if( options.type === 'ext' )
        {
        }
        else
        {
        }
        */
    }
    /**
     * Method to process a system message.
     *
     * @var object options      Defines an associative list of parameters.
     *      @param string type      Defines the id of the form to save ( adminForm if not set ).
     *      @param string message   Defines the message to display to the user.
     *
     * @return void
     *
     * @since 0.1.0
     */
    systemMessage(args) {
        let options = _.extend(args, {
            // Default values
            type: "",
            message: ""
        });
        if (options.msg !== '') {
            let messageWrap = document.querySelector('[data-toggle="isys"]').querySelector('.xrm-xmsg');
            messageWrap.innerHTML = options.msg;
            let messageToParse = messageWrap.querySelector('.xrm-xaler'), parsedMessage = null;
            // Here we will add success or error title
            if (messageToParse) {
                parsedMessage = messageToParse.innerHTML;
                // We also support a very simple syntax whereby a double-quote
                // in the msg string symbolizes a request for html bold wrappage
                parsedMessage = parsedMessage.replace(/\"(.*?)\"/g, function (c, m) { return '<b>' + m + '</b>'; });
                if (_.hasClass(messageToParse, 'success')) {
                    parsedMessage = '<span class="glyphicon glyphicon-ok-sign" aria-hidden="true"></span> <div class="xrm-xannounce success"><h3>Success!</h3></div> <p class="success">' + parsedMessage + '</p>';
                }
                else {
                    if (_.hasClass(messageToParse, 'error')) {
                        parsedMessage = '<span class="glyphicon glyphicon-alert" aria-hidden="true"></span> <div class="xrm-xannounce error"><h3>Attention!</h3></div> <p class="error">' + parsedMessage + '</p>';
                    }
                }
                messageWrap.innerHTML = parsedMessage;
            }
            if ((options.type === '') || (options.type === 0) || (options.type === '0')) {
                let isys = document.querySelector('[data-toggle="isys"]');
                var height = isys.clientHeight;
                isys.style.bottom = '-' + (height + 20) + 'px';
                isys.style.display = 'block';
                isys.classList.remove('.paused');
                /*
                isys.animate
                (
                    {
                        display: 'block',
                        bottom: '20px',
                    },
                    'fast'
                )
                .queue
                (
                    'delayHide',
                    function( next )
                    {
                        // Animation complete.
                        if( !$( isys ).is( ':hidden' ) )
                        {
                            $( isys ).delay( 10000 ).animate
                            (
                                {
                                    bottom: '-' + ( height + 20 ) + 'px'
                                },
                                'fast',
                                function()
                                {
                                    // Remember to hide the element
                                    $( isys ).css( { 'display': 'none' } );
                                }
                            );
                        }

                        next();
                    }
                )
                .dequeue( 'delayHide' );
                */
            }
            else {
                document.querySelector(options.type).fadeIn(800);
                document.querySelector(options.type).delay(10000).fadeOut(800);
            }
        }
    }
    /**
     * Method to build the markup for a toolbar specific to a view
     *
     * @param fn string Load or Destory a toolbar
     * @param args object Options
     *
     * @return void
     *
     * @since 0.1.0
     */
    toolbar(fn, args) {
        switch (fn) {
            /**
             * Method to load a toolbar for a view.
             *
             * @var object options          Defines an associative list of parameters.
             *      @param string display   Defines the display to load the toolbar for.
             *      @param string interface Defines the layout of the display to load the toolbar for (if any).
             *      @param string system    Defines the system the toolbar is for (i.e. "facility", "ext").
             *
             * @return void
             *
             * @since 1.0
             */
            case "load":
                {
                    let options = _.extend(args, {
                        // Default values
                        interface: "",
                        action: "",
                        system: "facility",
                        ext: ""
                    });
                    // Define authorized toolbar buttons
                    let toolbarOptions = ['start', 'spacer', 'tbnew', 'tbedit', 'tbcopy', 'tbup', 'tbdown', 'tbsave', 'tbsaveclose', 'tbsavenew', 'tbapply', 'tbpub', 'tbunpub', 'tbdelete', 'tbopt', 'tbsync', 'tbcancel', 'tbcancelopt', 'end'];
                    // Define toolbar content per page
                    let toolbar = false;
                    let toolbarHandle = 'xrm-facility-toolbar';
                    if (kux.hasOwnProperty('facilityToolbaritems')) {
                        toolbar = facilityToolbarItems;
                    }
                    if (options.system === 'ext') {
                        if (this.hasOwnProperty('extensionToolbarItems')) {
                            toolbar = extensionToolbarItems || {};
                        }
                        toolbarHandle = 'xrm-extension-toolbar';
                    }
                    // Prepare variable to store toolbar-to-make
                    let toolbarContent = "";
                    // Check if requested toolbar is an authorized option
                    if (!_.empty(toolbar[options.interface])) {
                        // Check if requested action toolbar is available
                        if (!_.empty(toolbar[options.interface][options.action])) {
                            _.each(toolbar[options.interface][options.action], function (key, value) {
                                // Check if requested button is an authorized option
                                if (toolbarOptions.includes(key)) {
                                    // Add content to toolbar string
                                    toolbarContent += value;
                                }
                            });
                        }
                        else { // There is no action toolbar requested or it does not exist. Fall back to default toolbar options for display (if any)
                            _.each(toolbar[options.ui], function (key, value) {
                                // Check if requested button is an authorized option
                                if (toolbarOptions.includes(key)) {
                                    // Add content to toolbar string
                                    toolbarContent += value;
                                }
                            });
                        }
                    }
                    // Render the toolbar content
                    this.receive({
                        // Prepare options array
                        content: toolbarContent,
                        type: toolbarHandle
                    });
                }
                break; // End "load" case.
            case "destroy":
                {
                    var options = _.extend(args, {
                        // Default values
                        interface: "",
                        action: "",
                        system: "facility"
                    });
                    let toolbarHandle = 'xrm-facility-toolbar';
                    if (options.system === 'ext') {
                        toolbarHandle = 'xrm-extension-toolbar';
                    }
                    this.receive({
                        // Prepare options array
                        content: "",
                        type: toolbarHandle
                    });
                }
                break; // End "destroy" case.
        }
    }
    ;
    /**
     * Parses a form by default, or performs one of several processes.
     *
     * @param args object  Object with options specific to the feature or method being facilitated for this form
     * @param fn   string  Specifies the form feature or method being facilitated for this form
     *
     * @return void
     *
     * @since 0.1.0
     */
    form(args, fn = null) {
        if (!fn || fn === "") {
            // Default action: Parse a form for its field/value pairs
            let options = _.extend(args, {
                formName: 'adminForm'
            });
            // Any kwaeri-editor (WYSYWYG) instances need to load the textarea so that we can find the input
            let theEditor = null;
            if (document.getElementById('#' + options.formName).querySelectorAll('.kwaeri-editor').length > 0) {
                theEditor = document.getElementById('#' + options.formName).querySelector('.kwaeri-editor');
            }
            if (theEditor && kwaeriEditor) {
                kwaeriEditor.post();
            }
            // Grab the form data
            let formData = new FormData(document.getElementById(options.formName));
            // Make it an array
            let data = Array.from(formData), obj = {}, j = 0;
            for (var i = 0; i < data.length; i++) {
                if (data[i][0] in obj) //Used for select multiple
                 {
                    var key = data[i][0] + '_' + j;
                    obj[key] = data[i][1];
                    j++;
                }
                else {
                    obj[data[i][0]] = data[i][1];
                }
            }
            ;
            Array.prototype.map.call(document.querySelectorAll('#' + options.formName + ' input[type=checkbox]:not(:checked)'), function (element) {
                return obj[element.name] = 0;
            });
            return obj;
        }
        if (fn === "reset") {
            /**
             * Method to reset a form
             *
             * @var object  options     An associative array of parameters
             *      @param string   form    Defines the id of the form to reset.
             *
             * @returns void
             *
             * @since 0.0.1
             */
            let options = _.extend(args, {
                // Default values
                formName: ""
            });
            let value = "";
            document.getElementById(options.formName).querySelectorAll('select')[0].value = value;
            var selectOption = document.querySelector(value);
            selectOption.checked = true;
            ;
            _.each(document.getElementById(options.formName).querySelectorAll('select'), function (key, val) {
                //document.getElementById( options.formName ).querySelectorAll( 'select' )[key].value = value
                this.value = value;
            });
            _.each(document.getElementById(options.formName).querySelectorAll('input[type=text]'), function (key, val) {
                //document.getElementById( options.formName ).querySelectorAll( 'input[type=text]' )[key].value =  value;
                this.value = value;
            });
            _.each(document.getElementById(options.formName).querySelectorAll('input[type=checkbox]'), function (key, val) {
                //document.getElementById( options.formName ).querySelectorAll( 'input[type=text]' )[key].removeAttribute( 'checked' );
                this.removeAttribute('checked');
            });
        }
        if (fn === "msMoveRows") {
            /**
             * Method to move select options from one multiple select box to another.
             * The function also sorts the options alphabetically after moving
             *
             * @var object  options     An associative array of parameters
             *      @param string   from    Defines the source multiple select box.
             *      @param string   to      Defines the destination multiple select box.
             *
             * @return void
             *
             * @since 1.0
             */
            let options = _.extend(args, {
                // Default values
                from: "",
                to: ""
            });
            let selectedItems = Array.from(document.querySelectorAll(options['from'] + ' :selected'));
            document.getElementById(options["to"]).appendChild(selectedItems);
            _.each(selectedItems, function (key, val) {
                // selectedItems[key].remove;
                this.remove;
            });
            let optionsToSort = document.getElementById(options['to'] + ' option');
            let sortableOptions = Array.from(optionsToSort.children);
            sortableOptions.sort(function (a, b) {
                if (a.innerHTML.toLowerCase() > b.innerHTML.toLowerCase()) {
                    return 1; //Need to use toLowerCase in case some users don't capitalize their name
                }
                else if (a.innerHTML.toLowerCase() < b.innerHTML.toLowerCase()) {
                    return -1;
                }
                else {
                    return 0;
                }
            });
            _.each(document.getElementById(options['to']).children, function (key, val) {
                //document.getElementById( options['to'] ).children[key].remove;
                this.remove;
            });
            _.each(sortableOptions, function (key, val) {
                // document.getElementById( options['to'] ).appendChild( sortableOptions[key] );
                document.getElementById(options['to']).appendChild(this);
            });
        }
        if (fn === "msToggleSelectAll") {
            /**
             * Method to select or deselect all values in a multiple select box.
             *
             * @var object  options     An associative array of parameters
             *      @param string   select      Defines the id of the multiple select box where you want all values selected.
             *      @param string   deseelct    Defines the id of the multiple select box where you want all values deselected.
             *
             * @return void
             *
             * @since 1.0
             */
            var options = _.extend(args, {
                // Default values
                select: "",
                deselect: ""
            });
            _.each(document.getElementById(options['select']), function (key, val) {
                //document.getElementById( options['select'] )[key].setAttribute( 'selected', 'selected' );
                this.setAttribute('selected', 'selected');
            });
            _.each(document.getElementById(options['deselect']), function (key, val) {
                //document.getElementById( options['deselect'] )[key].removeAttribute( 'selected' );
                this.removeAttribute('selected');
            });
        }
    }
    /**
     * Method for rendering a lightbox for modal views
     *
     * @var object options      An associative array of parameters
     *      @param int width        Defines a width to set the lightbox to, in pixels ( optional ).
     *      @param int height       Defines a height to set the lightbox to, in pixels (optional ).
     *      @param int vscroll      Defines whether to allow vertical scrolling ( 1 = yes, 0 = no ).
     *      @param int overlay      Defines whether to only call the overlay, set this to 1 ( true ), else 0 ( false ).
     *
     * @return void
     *
     * @since 0.1.0
     */
    lytebox(fn, args) {
        if (fn === "load") {
            let options = _.extend(args, {
                // Default values
                width: "",
                height: "",
                vscroll: 1,
                overlay: 0,
                interface: ""
            });
            if (options['overlay'] === 1) {
                // Here we render the elements of the lytebox overlay
                let kwaeriLyte = document.querySelector('[data-purpose="kwaeri-lyte"]'), kwaeriLyteContent = document.querySelector('[data-purpose="kwaeri-lyte"] .content-block');
                //document.querySelector( '[data-purpose="kwaeri-lyte"]' );
                //document.querySelector(  '[data-purpose="kwaeri-lyte"] .content-block' ).animate( { 'opacity': '.50' }, 300, 'linear' );
                //$( '#kwaeri-lyte .content-block' ).animate( { 'opacity': '1.00' }, 300, 'linear' );
                //$( '#kwaeri-lyte, #kwaeri-lyte .content-block' ).css( 'display', 'block' );
            }
            else {
                // Here we render the elements of the lytebox
                let kwaeriLyte = document.querySelector('[data-purpose="kwaeri-lyte"]'), kwaeriLytebox = document.querySelector('[data-purpose="kwaeri-lytebox"]'), kwaeriLyteboxContent = document.querySelector(']data-purpose="kwaeri-lytebox"] .content-block');
                // If a progress bar is requested, we have to hide the lytebox_xml in place of the progress-xml
                if (options['interface'] === 'pbar') {
                    // NOT SURE IF THIS WILL BE REIMPLEMENTED???
                    //$( '#\\:B\\:JC\\:LB\\:O, #\\:B\\:JC\\:LB, #\\:B\\:JC\\:LB\\:pb\\:x' ).animate( { 'opacity': '.50' }, 300, 'linear' );
                    //$( '#\\:B\\:JC\\:LB, #\\:B\\:JC\\:LB\\:pb\\:x' ).animate( { 'opacity': '1.00' }, 300, 'linear' );
                    //$( '#\\:B\\:JC\\:LB\\:O, #\\:B\\:JC\\:LB, #\\:B\\:JC\\:LB\\:pb\\:x' ).css( 'display', 'block' );
                }
                else {
                    // Here we render the elements of the lytebox overlay and lytebox
                    //$( '#kwaeri-lyte, #kwaeri-lytebox, #kwaeri-lytebox .content-block' ).animate( { 'opacity': '.50' }, 300, 'linear' );
                    //$( '#kwaeri-lytebox, #kwaeri-lytebox .content-block' ).animate( { 'opacity': '1.00' }, 300, 'linear' );
                    //$( '#kwaeri-lyte, #kwaeri-lytebox, #kwaeri-lytebox .content-block' ).css( 'display', 'block' );
                }
                // This enables users to scroll content that overflows the lightbox container
                if (options['vscroll'] == 1) {
                    kwaeriLytebox.style.overflowY = 'auto';
                }
                else {
                    kwaeriLytebox.style.overflowY = 'none';
                }
                /*
                    Here we get the current width and height of the lightbox after its content is loaded.  We then see
                    if the values are greater than our maximum desirable width/height ( which is 75% of viewable screen on both axis.
                    If so we set the width and height to the maximum value, otherwise we let it take the size of its content.
                */
                if (options['width'] != '' && options['width'] != null) {
                    kwaeriLytebox.style.width = options['width'];
                }
                else if (+kwaeriLytebox.style.width > (+kwaeriLyte.style.width * .75)) {
                    let width = (+kwaeriLyte.style.width * .75);
                    kwaeriLytebox.style.width = width.toFixed();
                    //$( '#kwaeri-lytebox' ).width( ( $( '#kwaeri-lyte' ).width() * .75 ) );
                }
                else if (+kwaeriLytebox.style.width < 400) {
                    kwaeriLytebox.style.width = "400";
                }
                if (options['height'] != '' && options['height'] != null) {
                    kwaeriLytebox.style.height = options['height'];
                }
                else if (+kwaeriLytebox.style.height > (+kwaeriLyte.style.height * .75)) {
                    let height = (+kwaeriLyte.style.height * .75);
                    kwaeriLytebox.style.height = height.toFixed();
                }
                else if (+kwaeriLytebox.style.height < 200) {
                    kwaeriLytebox.style.height = "200";
                }
                /*
                    Here we divide the width and height of the lytebox in half, and sutract those values
                    from the corresponding margins to keep the lytebox in the center of the screen.
                    We have to set the left and top via javascript, otherwise the display is glitchy and will
                    not behave properly.
                */
                let lyteboxWidth = +kwaeriLytebox.style.width, lyteboxHeight = +kwaeriLytebox.style.height, marginLeft = -(lyteboxWidth / 2), marginTop = -(lyteboxHeight / 2), handleWidth = lyteboxWidth + 10;
                kwaeriLytebox.style.left = '50%';
                kwaeriLytebox.style.top = '50%';
                kwaeriLytebox.style.marginLeft = marginLeft.toFixed();
                kwaeriLytebox.style.marginTop = marginTop.toFixed();
                // Here we set the offset and width of the header div after the main div has been modified to prevent any undesired behavior
                let kwaeriLyteboxHandle = document.querySelector('[data-purpose="kwaeri-handle-lytebox-drag"]');
                kwaeriLyteboxHandle.style.marginLeft = '-8px';
                kwaeriLyteboxHandle.style.marginTop = '-50px';
                kwaeriLyteboxHandle.style.width = handleWidth.toFixed();
                /*
                $( '#kwaeri-lytebox' ).draggable
                (
                    {
                        handle: '#kwaeri-handle-lytebox-drag'
                    }
                );
                */
            }
        }
        if (fn === "close") {
            /**
             * Method to close the lytebox.
             *
             * @return void
             *
             * @since 1.0
             */
            var options = _.extend(args, {
                // Default values
                interface: ""
            });
            let contentBlock;
            // Here we hide the lytebox and overlay
            //if( options['interface'] === 'pbar' )
            //{
            //    contentBlock = document.querySelector( '[data-purpose="lytebox-content"]' );
            //}else
            //{
            //    lyteboxElements = $( '#kwaeri-lyte, #kwaeri-lytebox, #kwaeri-lytebox .content-block' );
            //}
            let kwaeriLyte = document.querySelector('[data-purpose="kwaeri-lyte"]'), kwaeriLytebox = document.querySelector('[data-purpose="kwaeri-lytebox"]'), kwaeriLyteboxHandle = document.querySelector('[data-purpose="kwaeri-handle-lytebox-drag"]'), kwaeriLyteboxContent = document.querySelector(']data-purpose="kwaeri-lytebox"] .content-block');
            kwaeriLytebox.style.display = 'none';
            kwaeriLytebox.style.left = '';
            kwaeriLytebox.style.top = '';
            kwaeriLytebox.style.marginLeft = '';
            kwaeriLytebox.style.marginTop = '';
            kwaeriLytebox.style.width = 'auto';
            kwaeriLytebox.style.height = 'auto';
            kwaeriLytebox.style.overflowY = 'hidden';
            kwaeriLyteboxHandle.style.marginLeft = '';
            kwaeriLyteboxHandle.style.marginTop = '';
            kwaeriLyteboxHandle.style.width = '';
            /*
            kwaeriLyteboxContent.animate
            (
                'linear',
                function()
                {
                    contentBlock.css('display', 'none');

                    // Here we unset everything we set in renderlytebox() so that all the css values we set are unset and we have no conflicts.
                    // Removing this will make it so that after opening a small modal, opening a large one will make the display
                    // behave improperly.  In order to correct this, unsetting values we manually set with jQuery is required.

                    $( '#kwaeri-lytebox' ).css('display', 'none');
                    $( '#kwaeri-lytebox' ).css('left', '');
                    $( '#kwaeri-lytebox' ).css('top', '');
                    $( '#kwaeri-lytebox' ).css('margin-left', '' );
                    $( '#kwaeri-lytebox' ).css('margin-top', '' );

                    $( '#kwaeri-lytebox' ).css('width', 'auto');
                    $( '#kwaeri-lytebox' ).css('height', 'auto' );
                    $( '#kwaeri-lytebox' ).css('overflow-y','hidden');
                    $( '#kwaeri-handle-lytebox-drag' ).css('margin-left', '' );
                    $( '#kwaeri-handle-lytebox-drag' ).css('margin-top', '' );
                    $( '#kwaeri-handle-lytebox-drag' ).width();
                }
            );
            */
        }
    }
    /**
     * Clears the application display
     *
     * @param args The arguments object is considered persumptuously, and is currently not made use of.
     *
     * @return void
     *
     * @since 0.1.0
     */
    destroy(args) {
        document.querySelector('[data-toggle="isys"] .xrm-xmsg').innerHTML = "";
        document.querySelector('[data-purpose="]client-display"]').innerHTML = "";
    }
    /**
     * Method which scrolls to the top of the document
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.8
     */
    scrollToTop() {
        let element = document.scrollingElement || document.documentElement, to = 0, duration = 1000;
        const start = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop, change = to - start, startDate = +new Date();
        /**
         * Method to ease animation (smooth)
         *
         * @param t current time
         * @param b start time
         * @param c change in value
         * @param d duration
         *
         * @return number ?
         *
         * @since 0.1.0
         */
        let easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b;
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };
        let animateScroll = function () {
            const currentDate = +new Date, currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollTop = to;
            }
        };
        animateScroll();
    }
    /**
     * Method to instantiate all controls collectively
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.4
     */
    controls() {
        // Initialize funcitonal controls for the application
        this.functionalControls();
        // Initialize form controls for the application
        this.formControls();
    }
    /**
     * Initializes and implements controls which contribute to the functionality of the application
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.4
     */
    functionalControls() {
        /**** Instantiate the navigation drawer if it is present ****/
        this.drawers();
        /**** Instantiate the sidebar's mimic behavior if it is present ****/
        this.sidebars();
        /**** Instantiate the top-app-bar if it is present ****/
        this.topAppBars();
        /**** Instantiate the side-menu's behavior for setting/unsetting active classes on click if it is present  */
        this.sideMenu();
        /**** Instantiate and render any markdownBlocks in the document ****/
        this.markdownBlocks();
        /**** Instantiate and render any chartableCanvases in the docuent ****/
        this.chartables();
        /**** Instantiate the sidebar's menu system if sidebar/sidemenu is present ****/
        this.expandables();
        /**** Initialize Collapsibles  ****/
        this.collapsibles();
        /**** Ripple Effects ****/
        this.buttons();
        /**** Instantiate all dropdown menus ****/
        this.dropdowns();
        /**** Instantiate all navigation buttons ****/
        this.navigationButtons();
        /**** Initialize Draggables ****/
        this.draggables();
        /**** Initialize parallax feature ****/
        this.parallaxes();
        /**** Initialize scrollToTarget feature ****/
        this.scrollToTarget();
    }
    /**
     * Method which intializes all form-based controls from Material Design & Kwaeri
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.4
     */
    formControls() {
        /**** Initialize floatingLabels feature  ****/
        this.floatingLabels();
        /**** Initialize formFields feature  ****/
        this.formFields();
        /**** Initialize textFields feature  ****/
        this.textFields();
        /**** Initialize iconToggles feature  ****/
        this.iconToggles();
        /**** Initialize lineRipples feature  ****/
        this.lineRipples();
        /**** Initialize notchedOutlines feature  ****/
        this.notchedOutlines();
        /**** Initialize radios feature  ****/
        this.radios();
        /**** Initialize checkboxes feature  ****/
        this.checkboxes();
        /**** Initialize selects feature  ****/
        this.selects();
        /**** Initialize sliders feature  ****/
        this.sliders();
    }
    /**
     * Method to instantiate TopAppBars if any exist in the DOM
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    topAppBars() {
        // Get a handle to our TopAppBar
        let topAppBarElement = document.querySelector('[data-menu="top-menu"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.topAppBar = window.kwaeri.topAppBar || ((topAppBarElement) ? /* new MDCTopAppBar( topAppBarElement ) */ MDCTopAppBar.attachTo(topAppBarElement) : null);
        //topAppBar = MDCTopAppBar.attachTo( topAppBarElement )
        /* And instantiate it!
        _.each
        (
            topAppBarElements,
            function( key, topAppBarElement )
            {
                ( window as any ).kwaeri.topAppBars = new MDCTopAppBar( topAppBarElement );
                //console.log( 'TopAppBar Initialized!' );
            }
        );
        */
        // If rebound-scroll is set as a value to data-animation, create a scroll event handler that
        // will implement the desired behavior
        let reboundScrollElements = document.querySelectorAll('[data-animation="rebound-scroll"]');
        if (reboundScrollElements) {
            // Ensure a window property for userScrollPosition has been injected (maybe library.js was modified?)
            // To do so, set a variable named scrollTop to either of these two values (checking that either is set):
            let scrollTop = document.body.scrollTop || window.scrollY;
            // Then prepare a fallback incase body.scrollTop doesn't work for this effect (always returns 0?):
            if ((!document.body.scrollTop || document.body.scrollTop == 0) && window.scrollY && window.scrollY > 0) {
                // scrollTop should not be 0 if scrollY has a positive value:
                scrollTop = window.scrollY;
            }
            window.userScrollPosition = window.userScrollPosition || scrollTop;
            window.lastChangeDirectionPosition = window.lastChangeDirectionPosition || scrollTop;
            window.lastDirection = window.lastDirection || scrollTop;
            let animationPart1 = {
                targetMarginTop: null,
                attributeFunction: null,
                classFunction: null,
            }, animationPart2 = {
                targetMarginTop: '0px',
                attributeFunction: null,
                classFunction: null
            };
            _.each(reboundScrollElements, function (key, reboundScrollElement) {
                // Define the event handler for when our window is scrolled:
                let runReboundingTopAppBarScrollEventHandler = function () {
                    // Run the scrollTop fallback routine like we did when we initialized the topAppBar, each time we scroll:
                    let scrollTop = document.body.scrollTop || window.scrollY;
                    if ((!document.body.scrollTop || document.body.scrollTop == 0) && window.scrollY && window.scrollY > 0) {
                        // scrollTop should not be 0 if scrollY has a positive value:
                        scrollTop = window.scrollY;
                    }
                    // Prepare two animation objects, which will hold the details of the animations involved with the
                    // effect we are produing:
                    let reboundTransition = reboundScrollElement.style.transition, isRebounded = JSON.parse(reboundScrollElement.getAttribute('data-rebounded')), isInverted = _.hasClass(reboundScrollElement, '+inverse-colors'), invert = (!isInverted && (scrollTop > (window.lastChangeDirectionPosition + 100))) ? true : false, revert = (isInverted && ((scrollTop <= (window.lastChangeDirectionPosition - 100)) || scrollTop == 0)) ? true : false;
                    // The next 3 statements handle stateFlags which control the effect
                    if (window.userScrollPosition < scrollTop) {
                        // Scrolling Downwards (signify with +1 value )
                        if (window.lastDirection < 1) {
                            // Changed direction, update state flags
                            window.lastChangeDirectionPosition = scrollTop;
                            window.lastDirection = 1;
                        }
                    }
                    if (window.userScrollPosition > scrollTop) {
                        // Scrolling Upwards
                        if (window.lastDirection > -1) {
                            // Changed direction, update state flags
                            window.lastChangeDirectionPosition = scrollTop;
                            window.lastDirection = -1;
                        }
                    }
                    if (scrollTop == 0) {
                        if (window.lastDirection !== 0) {
                            window.lastChangeDirectionPosition = 0;
                            window.lastDirection = 0;
                        }
                    }
                    // Only run the animation if we are inverting or reverting our topAppbar:
                    if ((invert || revert && !isRebounded)) {
                        // A full invert or revert requires a full rebound effect, 2 parts. Lets start by setting the static marginTop transitions:
                        animationPart1.targetMarginTop = '-' + reboundScrollElement.scrollHeight + 'px';
                        animationPart1.attributeFunction = () => { reboundScrollElement.setAttribute('data-rebounded', 'true'); };
                        animationPart2.targetMarginTop = '0px';
                        animationPart2.attributeFunction = () => { reboundScrollElement.setAttribute('data-rebounded', 'false'); };
                        // Depending on whether or not we are inverting or reverting the rest happens in 1 of 2 orders
                        if (invert) {
                            //console.log( 'Inverting!' );
                            animationPart1.classFunction = () => { _.addClass(reboundScrollElement, '+inverse-colors'); };
                        }
                        else {
                            if (revert) {
                                //console.log( 'Reverting!' );
                                animationPart1.classFunction = () => { _.removeClass(reboundScrollElement, '+inverse-colors'); };
                            }
                        }
                        // Define part 2 of our transition effect handler.
                        let reboundTransitionFunc = function () {
                            // Set the transition to null, so it applies when we want it to:
                            reboundScrollElement.style.transition = null;
                            // Run the attribute function to change values of state flags
                            animationPart2.attributeFunction();
                            // Run the class function to change the color scheme
                            animationPart1.classFunction();
                            // Once the start marginTop is set, set the transtion so its prepped for our next frame
                            reboundScrollElement.style.transition = reboundTransition;
                            // on the next frame (as soon as the previous style change has taken effect),
                            // have the element transition to the target height
                            requestAnimationFrame(function () {
                                // Apply the marginTop change
                                reboundScrollElement.style.marginTop = animationPart2.targetMarginTop;
                            });
                        };
                        // Define part 1 of our transition effect handler.
                        let transitionFunc = function () {
                            // Set the transition to null, so it applies when we want it to:
                            reboundScrollElement.style.transition = null;
                            // Run the attribute function to change values of state flags
                            animationPart1.attributeFunction();
                            // Once the start marginTop is set, set the transtion so its prepped for our next frame
                            reboundScrollElement.style.transition = reboundTransition;
                            // on the next frame (as soon as the previous style change has taken effect),
                            // have the element transition to the target height
                            requestAnimationFrame(function () {
                                // Apply the marginTop change, its transition will process
                                reboundScrollElement.style.marginTop = animationPart1.targetMarginTop;
                                // Run part 2 of the animation in about a second:
                                setTimeout(function () {
                                    // Conserve resource and promote smoother animations:
                                    //if( !( ( window as any ).ticking ) )
                                    //{
                                    //( window as any ).ticking = true;
                                    window.requestAnimationFrame(function () {
                                        // Call the 2nd transition routine (part 2 of our animation):
                                        reboundTransitionFunc();
                                        // Set window.ticking to false at the end of an animation segment:
                                        window.ticking = false;
                                    });
                                    //}
                                }, 700);
                            });
                        };
                        // Conserve resource and promote smoother animations:
                        if (!window.ticking) {
                            window.requestAnimationFrame(function () {
                                // Set window.ticking to true in order to safe-guard animations:
                                window.ticking = true;
                                // Call the transition routine:
                                transitionFunc();
                            });
                        }
                    }
                    // Always update the stored scroll position.  Later on we'll modify this for some very unique behavior!
                    window.userScrollPosition = scrollTop;
                };
                // Attach the event handler to a listener
                window.addEventListener('scroll', runReboundingTopAppBarScrollEventHandler, false);
            });
        }
    }
    /**
     * Method to instantiate Material Design Navigation Drawers
     *
     * @param void
     *
     * @returns void
     *
     * @since 0.1.0
     */
    drawers() {
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        //( window as any ).kwaeri.drawer = ( window as any ).kwaeri.drawer || null;
        // Then figure out if the drawer is a hybrid - or is modal, dismissible, dismissible-flex, or permanent:
        let topAppBarElement = document.querySelector('[data-menu="top-menu"]'), drawerElement = document.querySelector('[data-menu="side-menu"]'), drawerType = (drawerElement) ? drawerElement.getAttribute('data-drawer') : null, isHybrid = ((drawerType === 'hybrid-permanent-modal') || (drawerType === 'hybrid-dismissible-modal') || (drawerType === 'hybrid-permanent-dismissible')) ? true : false, isPermanent = (drawerElement && !_.hasClass(drawerElement, 'mdc-drawer--modal') && !_.hasClass(drawerElement, 'mdc-drawer--dismissible')) ? true : false, isDismissible = (drawerElement && _.hasClass(drawerElement, 'mdc-drawer--dismissible')) ? true : false, isModal = (drawerElement && _.hasClass(drawerElement, 'mdc-drawer--modal')) ? true : false, flexBody = (drawerType === 'dismissible-flex') ? true : false;
        // If we're supporting a hybrid/responsive drawer:
        if (isHybrid) {
            // Clear the flags previously set (they are only used as-is for non hybrid/responsive drawers):
            isModal = false;
            isDismissible = false;
            isPermanent = false;
            // Now flag the appropriate drawer type based on window conditions:
            if (window.innerWidth <= 1320) {
                switch (drawerType) {
                    // dismissible at larger widths, modal at smaller widths
                    case 'hybrid-dismissible-modal':
                        {
                            _.removeClass(drawerElement, 'mdc-drawer--dismissible');
                            _.addClass(drawerElement, 'mdc-drawer--modal');
                            isModal = true;
                            drawerElement.setAttribute('data-menu-type', 'modal');
                        }
                        break;
                    // permanent at larger widths, modal at smaller widths
                    case 'hybrid-permanent-modal':
                        {
                            _.addClass(drawerElement, 'mdc-drawer--modal');
                            isModal = true;
                            drawerElement.setAttribute('data-menu-type', 'modal');
                        }
                        break;
                    // permanent at larger widths, dismissible at smaller widths
                    case 'hybrid-permanent-dismissible':
                        {
                            _.addClass(drawerElement, 'mdc-drawer--dismissible');
                            isDismissible = true;
                            drawerElement.setAttribute('data-menu-type', 'dismissible');
                        }
                        break;
                }
            }
            else {
                switch (drawerType) {
                    case 'hybrid-dismissible-modal':
                        {
                            _.removeClass(drawerElement, 'mdc-drawer--modal');
                            _.addClass(drawerElement, 'mdc-drawer--dismissible');
                            isDismissible = true;
                            drawerElement.setAttribute('data-menu-type', 'dismissible');
                        }
                        break;
                    case 'hybrid-permanent-modal':
                        {
                            _.removeClass(drawerElement, 'mdc-drawer--modal');
                            isPermanent = true;
                            drawerElement.setAttribute('data-menu-type', 'permanent');
                        }
                        break;
                    case 'hybrid-permanent-dismissible':
                        {
                            _.removeClass(drawerElement, 'mdc-drawer--dismissible');
                            isPermanent = true;
                            drawerElement.setAttribute('data-menu-type', 'permanent');
                        }
                        break;
                }
            }
        }
        // Initialize the appropriate drawer type:::
        //let drawer = null;
        if (drawerElement && (isModal || isDismissible) && !isPermanent) {
            // Modal or Dismissible drawers:
            //( window as any ).kwaeri.drawer = MDCDrawer.attachTo( drawerElement );
            drawer = MDCDrawer.attachTo(drawerElement);
            // Set the drawer, by default, to closed:
            //( window as any ).kwaeri.drawer.open = false;
            drawer.open = false;
            // Set our drawer open/close button:( window as any ).kwaeri.topAppBar
            //if( topAppBar )
            //{
            //( window as any ).kwaeri.
            topAppBar = MDCTopAppBar.attachTo(topAppBarElement);
            topAppBar.setScrollTarget(document.querySelector('[data-purpose="client-display"]'));
            topAppBar.listen('MDCTopAppBar:nav', () => {
                //( window as any ).kwaeri.drawer.open = !( window as any ).kwaeri.drawer.open;
                drawer.open = !drawer.open;
                window.focus();
            });
            //}
            document.querySelector('[data-menu="navigation-drawer"]').addEventListener('click', (event) => {
                //( window as any ).kwaeri.drawer.open = false;
                drawer.open = !drawer.open;
                window.focus();
            });
            document.querySelector('[data-menu="side-menu"] .mdc-list').addEventListener('click', (event) => {
                //( window as any ).kwaeri.drawer.open = false;
                drawer.open = false;
                window.focus();
            });
        }
        else {
            // Permanent drawer:
            if (drawerElement && isPermanent && !isModal && !isDismissible) {
                //( window as any ).kwaeri.drawer = new MDCList( document.querySelector( '[data-menu="side-menu"] .mdc-list' ) );
                //( window as any ).kwaeri.drawer.wrapFocus = true;
                drawer = new MDCList(document.querySelector('[data-menu="side-menu"] .mdc-list'));
                drawer.wrapFocus = true;
            }
        }
        // Store the drawer in the window instance for potential access later on
        //( window as any ).kwaeri.drawer = drawer;
    }
    /**
     * Method to imitate appbar behavior when scrolling
     *
     * @param void
     *
     * @return void
     *
     * @since 0.0.1
     */
    sidebars() {
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.sidebar = window.kwaeri.sidebar || null;
        // Get a live/active handle to our sidebar, if it exists:
        let sidebar = document.querySelector('[data-menu="side-menu"]');
        // Define the Mimic Behavior (elevate on scroll)
        let runSidebarMimicBehavior = function (xScrollPosition, yScrollPosition) {
            let sidebar = document.querySelector('[data-menu="side-menu"]');
            if (yScrollPosition >= 50) {
                if (!_.hasClass(sidebar, '+scrolled')) {
                    _.addClass(sidebar, '+scrolled');
                }
            }
            else {
                if (_.hasClass(sidebar, '+scrolled')) {
                    _.removeClass(sidebar, '+scrolled');
                }
            }
            // Set window.ticking to false at the end of an animation segment:
            window.ticking = false;
        };
        // Define the scroll-event-handler (all about smooth UI yo)
        let runSidebarScrollEventHandler = function () {
            let xScrollPosition = window.scrollX;
            let yScrollPosition = window.scrollY;
            if (!window.ticking) {
                window.requestAnimationFrame(function () {
                    // Set window.ticking to true in order to safe-guard animations:
                    window.ticking = true;
                    // Invoke the animation segment:
                    runSidebarMimicBehavior(xScrollPosition, yScrollPosition);
                });
            }
        };
        // Define the sidebar/drawer's event-handler for when the window is resized:
        let runSidebarResizeEventHandler = function () {
            // Get a handle to our sidebar, and grab the drawer's type::
            let sidebar = document.querySelector('[data-menu="side-menu"]'), drawerType = sidebar.getAttribute('data-drawer');
            /**
             * Configure drawer attributes so that reinitialization results in drawer responsive-ness
             *
             * @param { string } intendedState The string value of `data-menu-type` representing what the sidemenu's drawer-type should be - or is.
             * @param { boolean } showButton  Whether or not to show the drawer toggle switch. Yes for smaller screens (modal esp), No for larger.
             * @param { boolean } allowFlex Whether or not to allow content to flex and condense into remaining space (or not). Yea for
             *                              larger screens, No for smaller.
             *
             * @returns { void }
             *
             * @since 0.1.0
             *
             */
            let changeDrawerType = (showButton = false, allowFlex = false) => {
                // Get a handle to the topAppBar's [side] menu button and to the main-content div:
                let navigationDrawerButton = document.querySelector('[data-menu="navigation-drawer"]'), contentDiv = document.querySelector('[data-feature="focus"]');
                // Show or hide the button for manually toggling the drawer:
                (showButton) ? _.removeClass(navigationDrawerButton, 'hidden') : _.addClass(navigationDrawerButton, 'hidden');
                // Flex the main content away from the left margin, accommodating the drawer - or not:
                (allowFlex) ? _.addClass(contentDiv, '+flex-side-menu') : _.removeClass(contentDiv, '+flex-side-menu');
                // Delete the existing drawer's javascript instance:
                //( window as any ).kwaeri.
                //( window as any ).kwaeri.drawer.destroy();
                drawer.destroy();
                // And initialize the drawer:
                //( window as any ).kux.controls();
                window.kux.drawers();
                window.kux.topAppBars();
            };
            if (window.innerWidth <= 1320) {
                switch (drawerType) {
                    case 'hybrid-dismissible-modal':
                        {
                            if (!_.hasClass(sidebar, 'mdc-drawer--modal')) {
                                changeDrawerType(true, false);
                            }
                        }
                        break;
                    case 'hybrid-permanent-modal':
                        {
                            if (!_.hasClass(sidebar, 'mdc-drawer--modal')) {
                                changeDrawerType(true, false);
                            }
                        }
                        break;
                    case 'hybrid-permanent-dismissible':
                        {
                            if (!_.hasClass(sidebar, 'mdc-drawer--dismissible')) {
                                changeDrawerType(true, false);
                            }
                        }
                        break;
                }
            }
            else {
                switch (drawerType) {
                    case 'hybrid-dismissible-modal':
                        {
                            if (!_.hasClass(sidebar, 'mdc-drawer--dismissible')) {
                                changeDrawerType(false, true);
                            }
                        }
                        break;
                    case 'hybrid-permanent-modal':
                        {
                            if (_.hasClass(sidebar, 'mdc-drawer--modal') || _.hasClass(sidebar, 'mdc-drawer--dismissible')) {
                                changeDrawerType(false, true);
                            }
                        }
                        break;
                    case 'hybrid-permanent-dismissible':
                        {
                            if (_.hasClass(sidebar, 'mdc-drawer--modal') || _.hasClass(sidebar, 'mdc-drawer--dismissible')) {
                                changeDrawerType(false, true);
                            }
                        }
                        break;
                }
            }
        };
        // Only if the [data-menu="side-menu"] was found.
        if (sidebar) {
            window.addEventListener("scroll", runSidebarScrollEventHandler, false);
            window.addEventListener("resize", runSidebarResizeEventHandler, false);
            // Store the sidebar in the window instance for potential access at a later point
            window.kwaeri.sidebar = sidebar;
        }
    }
    /**
     * Method for setting and removing active classes on the side-menu
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    sideMenu() {
        let sideMenu = document.querySelector('[data-menu="side-menu"]'), menuItems = document.querySelectorAll('[data-menu-item]'), subMenuItems = document.querySelectorAll('[data-submenu-item]');
        // Start with normal menu items
        _.each(menuItems, function (key, menuItem) {
            // Check if menu item is expandable, if not, define an onclick listener
            let hasAttribute = menuItem.hasAttribute('data-expandable');
            if (!hasAttribute) {
                let menuItemClickEventHandler = function (e) {
                    let sideMenu = document.querySelector('[data-menu="side-menu"]'), menuItems = document.querySelectorAll('[data-menu-item]'), subMenuItems = document.querySelectorAll('[data-submenu-item]');
                    // Remove active from all menu and submenu items
                    _.each(menuItems, function (key, topLevelMenuItem) {
                        if (_.hasClass(topLevelMenuItem, 'active')) {
                            _.removeClass(topLevelMenuItem, 'active');
                        }
                    });
                    _.each(subMenuItems, function (key, subLevelMenuItem) {
                        if (_.hasClass(subLevelMenuItem, 'active')) {
                            _.removeClass(subLevelMenuItem, 'active');
                        }
                    });
                    // Then add active to this menu item
                    _.addClass(menuItem, 'active');
                };
                // Define an onclick handler that will remove active from any other menu item and
                // set it on this item as an active class
                menuItem.addEventListener('click', menuItemClickEventHandler, false);
            }
        });
        // Continue on with the submenu items
        _.each(subMenuItems, function (key, subMenuItem) {
            let subMenuItemClickEventHandler = function (e) {
                // First clear active from all menu items and submenu items that might have it:
                _.each(menuItems, function (key, topLevelMenuItem) {
                    if (_.hasClass(topLevelMenuItem, 'active')) {
                        _.removeClass(topLevelMenuItem, 'active');
                    }
                });
                _.each(subMenuItems, function (key, subLevelMenuItem) {
                    if (_.hasClass(subLevelMenuItem, 'active')) {
                        _.removeClass(subLevelMenuItem, 'active');
                    }
                });
                // And add the active class to this subMenu Item, AND its parent:
                // Find the parent of our submenu item
                _.addClass(subMenuItem.parentElement.parentElement.parentElement.parentElement.querySelector('[data-menu-item]'), 'active');
                _.addClass(subMenuItem, 'active');
            };
            subMenuItem.addEventListener('click', subMenuItemClickEventHandler, false);
        });
        // Now our sidebar should handle setting active classes and removing active classes appropriately!
    }
    markdownBlocks() {
        // Query all the markdown blocks in the document:
        let markdownBlocks = document.querySelectorAll('[data-markdown="render"]');
        // For each of them, let's render the markdown:
        _.each(markdownBlocks, (key, markdownBlock) => {
            // Check if the markdown has already been rendered:
            let markdownRendered = (markdownBlock) ? JSON.parse(markdownBlock.getAttribute('data-rendered')) : false;
            // If not:
            if (!markdownRendered) {
                // Get the markdown from the markdown block
                let markdown = markdownBlock.innerHTML;
                // Convert it to HTML:
                let html = _.markdown.makeHtml(markdown);
                // And replace the markdown in the markdown block with the rendered content:
                markdownBlock.innerHTML = html;
                // Finally, mark that the block has been rendered:
                markdownBlock.setAttribute('data-rendered', 'true');
            }
        });
    }
    markdownEditors() {
        console.log('Markdown Editing is not yet implemented.');
    }
    chartables() {
        let chartableCanvases = document.querySelectorAll('[data-chart="canvas"]'), chartInitialized;
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.chartables = [];
        _.each(chartableCanvases, function (key, chartableCanvas) {
            // Check if the chart is initialized:
            chartInitialized = (chartableCanvas) ? JSON.parse(chartableCanvas.getAttribute('data-chart-initialized')) : false;
            // If not, do so:
            if (!chartInitialized) {
                // Get the chart data:
                let dataChartData = JSON.parse(chartableCanvas.getAttribute('data-chart-data'));
                // Initialize the chart using the provided data:
                window.kwaeri.chartables.push(new chart(chartableCanvas, dataChartData));
                // Mark the chart as initialized:
                chartableCanvas.setAttribute('data-chart-initialized', 'true');
            }
        });
    }
    /**
     * Method to intialize and implement expandable sections, like the ones used in the side-menu
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    expandables() {
        let sideMenu = document.querySelector('[data-menu="side-menu"]'), sideMenuInitialized = (sideMenu) ? JSON.parse(sideMenu.getAttribute('data-menu-initialized')) : false, expandableTriggers = document.querySelectorAll('[data-expandable]');
        // Set a flag for initializing the sideMenu expandables
        let atLeastOneSideMenuExpandableInitialized = false;
        _.each(expandableTriggers, function (key, expandableItem) {
            let type = expandableItem.getAttribute('data-expandable-type'), expandableId = expandableItem.getAttribute('data-expandable'), expandable = document.querySelector('[data-expand="' + expandableId + '"]'), initialized = _.hasClass(expandable, '+initialized'), negHeight = '-' + expandable.scrollHeight + 'px';
            // If we are not initialized, first let's calculate the submenu heights, and set the margin-top
            // to less their values, and unhide them so that they function properly.
            if (!initialized) {
                // Set the margin-top value
                expandable.style.marginTop = negHeight;
                // Unhide the submenu
                _.addClass(expandable, '+initialized');
            }
            switch (type) {
                case 'side-menu':
                    {
                        // Define the event handler for when our expandable menu toggle is clicked
                        let runExpandableButtonClickEventHandler = function () {
                            // Calculate the height of our submenu, and take a copy of the transition style
                            let expandableTransition = expandable.style.transition, transAttrFunc = null, targetMarginTop = null, isExpanded = JSON.parse(expandable.getAttribute('data-expanded'));
                            // Setting up values to use in transition function depending on current state of the menu
                            if (isExpanded) {
                                targetMarginTop = '-' + expandable.scrollHeight + 'px';
                                transAttrFunc = () => { expandable.setAttribute('data-expanded', 'false'); expandableItem.setAttribute('aria-expanded', 'false'); };
                            }
                            else {
                                targetMarginTop = '0px';
                                transAttrFunc = () => { expandable.setAttribute('data-expanded', 'true'); expandableItem.setAttribute('aria-expanded', 'true'); };
                            }
                            // Set the transition to null, so it applies when we want it to:
                            expandable.style.transition = null;
                            // Define our transition effect handler based upon the action we have to take
                            let transitionFunc = function () {
                                // Run the attribute function to change values of state flags
                                transAttrFunc();
                                // Once the start marginTop is set, set the transtion so its prepped for our next frame
                                expandable.style.transition = expandableTransition;
                                // on the next frame (as soon as the previous style change has taken effect),
                                // have the element transition to the target height
                                requestAnimationFrame(function () {
                                    expandable.style.marginTop = targetMarginTop;
                                    // Set window.ticking to false at the end of an animation segment:
                                    window.ticking = false;
                                });
                            };
                            // Conserve resource and promote smoother animations:
                            if (!window.ticking) {
                                window.requestAnimationFrame(function () {
                                    // Set window.ticking to true in order to safe-guard animations:
                                    window.ticking = true;
                                    // Call the transition routine
                                    transitionFunc();
                                });
                            }
                        };
                        // Attach the event handler to a listener
                        expandableItem.addEventListener('click', runExpandableButtonClickEventHandler, false);
                        atLeastOneSideMenuExpandableInitialized = true;
                    }
                // Other types as they are created
            }
        });
        if (sideMenu && !sideMenuInitialized && atLeastOneSideMenuExpandableInitialized) {
            // This means there was a sideMenu, and it was not initialized - but it is now :)
            sideMenu.setAttribute('data-menu-initialized', 'true');
        }
    }
    /**
     * Method to initialize and implement a collapsible feature
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    collapsibles() {
        let collapseButtons = document.querySelectorAll('[data-collapse]');
        _.each(collapseButtons, function (key, collapseButton) {
            let runCollapsibleButtonClickEventHandler = function () {
                // Get short handles to our elements
                let dataHintId = collapseButton.getAttribute('data-collapse'), dataCollapsible = document.querySelector('[data-collapsible="' + dataHintId + '"]'), dataCollapsibleWrap = document.querySelector('[data-collapsible-wrap="' + dataHintId + '"]'), dataCollapsibleHint = document.querySelector('[data-collapsible-hint="' + dataHintId + '"]'), dataCollapsibleContent = document.querySelector('[data-collapsible-content="' + dataHintId + '"]');
                // First thing to do is calculate heights, so we can set a height value in-line when the time comes
                // Also get the transition for our element-to-animate
                let collapsibleHintHeight = dataCollapsibleHint.scrollHeight, collapsibleContentHeight = dataCollapsibleContent.scrollHeight, collapsibleWrapTransition = dataCollapsibleWrap.style.transition;
                // Now remove the transition from the wrapper, so it doesnt fire prematurely (before height is set):
                dataCollapsibleWrap.style.transition = '';
                // Determine if we're hiding or showing, and set up some in-scope variables
                let isCollapsed = JSON.parse(dataCollapsible.getAttribute('data-collapsed')), transClassFunc = null, startHeight = null, targetHeight = null;
                // Set the start and target heights, the routine to run, and our aria-expanded value for screen readers
                if (isCollapsed) {
                    startHeight = collapsibleHintHeight + 'px';
                    targetHeight = collapsibleContentHeight + 'px';
                    transClassFunc = () => { _.removeClass(collapseButton, 'flip'); _.removeClass(dataCollapsible, 'collapsed'); };
                    dataCollapsible.setAttribute('aria-expanded', 'true');
                    dataCollapsible.setAttribute('data-collapsed', 'false');
                }
                else {
                    startHeight = collapsibleContentHeight + 'px';
                    targetHeight = collapsibleHintHeight + 'px';
                    transClassFunc = () => { _.addClass(collapseButton, 'flip'); _.addClass(dataCollapsible, 'collapsed'); };
                    dataCollapsible.setAttribute('aria-expanded', 'false');
                    dataCollapsible.setAttribute('data-collapsed', 'true');
                }
                // With our values set and routines determined, setup a function to run via requestAnimationFrame for smooth transitions
                let transitionFunc = function () {
                    // Handle setting/removing classes based on the situation:(collapse, expand)
                    transClassFunc();
                    // Set the height ahead of time, but in requestAnimationFrame so it doesnt become visible before we  pre-render it
                    dataCollapsibleWrap.style.height = startHeight;
                    // Once the start height is set, set the transtion so its prepped for our next frame
                    dataCollapsibleWrap.style.transition = collapsibleWrapTransition;
                    // on the next frame (as soon as the previous style change has taken effect),
                    // have the element transition to the target height
                    requestAnimationFrame(function () {
                        dataCollapsibleWrap.style.height = targetHeight;
                        // Set window.ticking to false at the end of an animation segment:
                        window.ticking = false;
                    });
                };
                // Define an event listener handler to return the height value to its 'auto' setting when the transition is done
                let nullifyHeightFunc = function () {
                    dataCollapsibleWrap.removeAttribute('style');
                };
                // Assing the handler to an event listener on our container
                dataCollapsibleWrap.addEventListener('transitionend', nullifyHeightFunc, false);
                // Conserve resource and promote smoother animations:
                if (!window.ticking) {
                    window.requestAnimationFrame(function () {
                        // Set window.ticking to true in order to safe-guard animations:
                        window.ticking = true;
                        // Call the transition routine
                        transitionFunc();
                        // Remove the event listener
                        dataCollapsibleWrap.removeEventListener('transitionend', nullifyHeightFunc);
                    });
                }
            };
            // Attach the event handler to a listener
            collapseButton.addEventListener('click', runCollapsibleButtonClickEventHandler, false);
        });
    }
    /**
     * Method to instantiate any MDComponent buttons if they exist in the DOM
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    buttons() {
        let buttons = document.querySelectorAll('.mdc-button');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.buttons = [];
        _.each(buttons, function (key, button) {
            window.kwaeri.buttons.push(new MDCRipple(button));
        });
    }
    /**
     * Method to instantiate Material Design Dropdown Menus
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    dropdowns() {
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.dropdowns = [];
        _.each(document.querySelectorAll('[data-dropdown]'), function (key, value) {
            // Instantiation
            let menuElementId = document.querySelectorAll('[data-dropdown]')[key].getAttribute('data-dropdown'), menuElement = document.querySelector('[data-menu="dropdown"][data-for="' + menuElementId + '"]'), menu = new mdc.menu.MDCMenu(menuElement), menuButtonElement = document.querySelector('[data-dropdown="' + menuElementId + '"]');
            // Toggle menu open
            let runMenuButtonClickEventHandler = function () {
                menu.open = !menu.open;
            };
            menuButtonElement.addEventListener('click', runMenuButtonClickEventHandler);
            // Listen for selected item
            let runMDCMenuSelectedEventHandler = function () {
                var detail = event.detail;
            };
            // This is one way we can store event handlers to reference for removal:
            //( window as any ).kux.eventHandlers.runMDCMenuSelectedEventHandler = runMDCMenuSelectedEventHandler;
            menuElement.addEventListener('MDCMenu:selected', runMDCMenuSelectedEventHandler);
            // Set Anchor Corner to Bottom End
            menu.setAnchorCorner(3); // The value is incremented in order like in CSS properties (i think):
            // top-left: 1
            // top-right: 2
            // bottowm-right 3
            // bottom-left: 4
            // Turn off menu open animations
            // menu.quickOpen = true;
            // Store the dropdown in the window instance for potential access at a later point
            window.kwaeri.dropdowns.push(menu);
        });
    }
    /**
     * Method which sets a click event handler for an ajax support link.
     *
     *      [NOTE]
     *      When JS is not available all navigation should work through HREF appropriately
     *      - Best of _both_ worlds! -
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    navigationButtons() {
        let thisInstance = this;
        // We'll use data-request as our flag for making a navigation request. Data-request,
        // will also serve to work for uploads, or serving downloads even.
        let navigationButtons = document.querySelectorAll('[data-request="navigation"]');
        // For each navigation button, let us prepare a click hadler:
        _.each(navigationButtons, function (key, navigationButton) {
            var properties = {
                // Default values
                path: "",
                interface: "home",
                action: null,
                mod: null,
                type: "normal",
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                dataType: 'html',
                data: null,
                method: "GET"
            };
            let href = navigationButton.getAttribute('href'), parsedPieces = [];
            if (href) {
                //console.log( 'navigation button!');
                //console.log( href );
                // Ensure the path begins with a slash
                href = (href.charAt(0) !== '/') ? '/' + href : href;
                // Now spliting it will work the same always
                let hrefPieces = href.split('/');
                // Since javascript is working, let's remove the href attribute
                navigationButton.removeAttribute('href');
                // Sort the pieces
                if (hrefPieces.length > -1) {
                    for (var i = 1; i < hrefPieces.length; i++) {
                        parsedPieces.push(hrefPieces[i]);
                    }
                }
                if (parsedPieces.length >= 1) {
                    if (parsedPieces.length >= 3 && parsedPieces[0] === 'extensions') {
                        properties.path = parsedPieces[0] + '/' + parsedPieces[1] + '/' + parsedPieces[2] + '/';
                        properties.interface = parsedPieces[3];
                        properties.action = parsedPieces[4];
                    }
                    else {
                        properties.interface = parsedPieces[0];
                        properties.action = parsedPieces[1];
                    }
                }
                else {
                    console.error('href attribute was missing parts or malformed');
                }
                // Set the onclick handler
                let navigationButtonClickEventHandler = function () {
                    // Grab active parameters
                    let datas = _.data(navigationButton);
                    if (datas.system) {
                        properties.type = datas.system;
                    }
                    if (datas.mime) {
                        properties.contentType = datas.mime;
                    }
                    if (datas.mine) {
                        properties.dataType = datas.mine;
                    }
                    if (datas.params) {
                        properties.data = JSON.parse(datas.params);
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xnew')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.parentId = parentId;
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xadd')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        if (!properties.data.forward) {
                            properties.data.forward = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.forward = { action: properties.data.forward.action || 'index', data: { parentId: parentId } };
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xar')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        if (!properties.data.forward) {
                            properties.data.forward = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.forward = { action: properties.data.forward.action || 'index', data: { parentId: parentId } };
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xcancel')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.parentId = parentId;
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xur')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        if (!properties.data.forward) {
                            properties.data.forward = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.forward = { action: properties.data.forward.action || 'index', data: { parentId: parentId } };
                    }
                    if (_.hasClass(navigationButton, 'xrm-child-xdelete')) {
                        if (!properties.data) {
                            properties.data = {};
                        }
                        if (!properties.data.forward) {
                            properties.data.forward = {};
                        }
                        let parentId = document.querySelector('input:hidden[name=parentId]').value;
                        properties.data.forward = { action: properties.data.forward.action || 'index', data: { parentId: parentId } };
                    }
                    if (datas.mod) {
                        properties.mod = datas.mod;
                    }
                    if (datas.method) {
                        properties.method = datas.method;
                    }
                    //event.preventDefault();
                    if ((_.hasClass(navigationButton, 'xrm-xedit') || _.hasClass(navigationButton, 'xrm-xcopy')) && datas.system === 'lbox' && datas.params) {
                        thisInstance.receive({
                            type: 'lbox',
                            content: 'You must first select an item from the list (i.e. put a check in the checkbox of the corresponding row).'
                        });
                    }
                    else {
                        thisInstance.request({
                            type: properties.type,
                            contentType: properties.contentType,
                            path: properties.path,
                            interface: properties.interface,
                            action: properties.action,
                            mod: properties.mod,
                            method: properties.method,
                            dataType: properties.dataType,
                            data: properties.data
                        });
                    }
                };
                navigationButton.addEventListener('click', navigationButtonClickEventHandler, false);
            }
        });
    }
    /**
     * Method to intialize and implement the draggable feature
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    draggables() {
        // Every element with a data-drag attribute will be able to be dragged
        let draggableElements = document.querySelectorAll('[data-drag]');
        // Step 1:  Before dragging, some handle must be selected by holding the  mouse left button down over it
        let draggableElementMouseDownEventHandler = function (e) {
            // Ensure we have access to event information & 'this'
            e = e || window.event;
            if (e.which === 1) {
                let draggable = this;
                // Get the mouse cursor position at startup
                window.dragPositions = { lastX: e.clientX, lastY: e.clientY };
                // Set the onmouseup event handler for the document
                document.onmouseup = draggableElementMouseUpEventHandler.bind(draggable);
                // Set the onmousemove event handler for the document
                document.onmousemove = draggableElementMouseDragEventHandler.bind(draggable);
            }
        };
        //Step 2:  While the mouse left button is being held down over an eligible element, if the mouse is
        // moved alon the x or y axis we will move the div along with it
        let draggableElementMouseDragEventHandler = function (e) {
            // Ensure we have access to event information & 'this'
            e = e || window.event;
            let draggable = this;
            // Create an animation handler
            let positionElementOnMouseMove = function (e, draggable) {
                // Calculate differences horizontally  and vertically from the previous and current positions
                let positionDifferenceX = window.dragPositions.lastX - e.clientX;
                let positionDifferenceY = window.dragPositions.lastY - e.clientY;
                // Store the current positions as the last postiions for the next loop
                window.dragPositions.lastX = e.clientX;
                window.dragPositions.lastY = e.clientY;
                // Set the top and left of the draggable to a position which is the difference we just calculated from its current offsets.
                draggable.style.left = (draggable.offsetLeft - positionDifferenceX) + 'px';
                draggable.style.top = (draggable.offsetTop - positionDifferenceY) + 'px';
                // Set window.ticking to false at the end of an animation segment:
                window.ticking = false;
            };
            // Conserve resource and promote smoother animations:
            if (!window.ticking) {
                window.requestAnimationFrame(function () {
                    // Set window.ticking to true in order to safe-guard animations:
                    window.ticking = true;
                    // Run the draggable animation routine:
                    positionElementOnMouseMove(e, draggable);
                });
            }
        };
        // Step 3: On mouse up, clear event handlers:
        let draggableElementMouseUpEventHandler = function (e) {
            e = e || window.event;
            if (e.which === 1) {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        };
        // Set up draggable support for any participating elements:
        _.each(draggableElements, function (key, draggable) {
            let handleId = draggable.getAttribute('data-drag'), handle = (handleId) ? document.querySelector('[data-handle="' + handleId + '"]') : draggable;
            if (handle) {
                //console.log( handle );
                handle.onmousedown = draggableElementMouseDownEventHandler.bind(draggable);
            }
        });
    }
    /**
     * Method that manages/implements a parallax effect on layers of a view
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    parallaxes() {
        //console.log( 'we running prallax methods!' );
        /******************************************************************************
         * All elements within the parallax group should be layered ahead of time via
         * a predefined z-index setting: i.e. elevated-by-x
         * properly.
         *
         * From there, each layer should have one main attribute defined:
         *
         * data-parallax-animation: xxx
         *
         * The animation property could be one of several possible values:
         *      - scroll-left, scroll-right, scroll-up, scroll-down
         *      - fade-out, fade-in
         *      - flip-left, flip-right, flip-up, flip-down
         *      - roll-left, roll-right
         *
         * There will be other data-animation-* attributes to set, depending on the value
         * of the main animation attribute that is specifiedssss:
         *
         * Scroll Animations:
         *
         *      Require the following:
         *      - data-animation-speed:
         *      -
         *
         *
         * Each layer should have several data-* properties, and will be dependent upon the main attribute:
         *
         * data-parallax-animation: The type of animation to enact on the element
         * data-animation:
         *
         ******************************************************************************/
        // Start by getting a selector to each of the layers
        let checkForParallaxOnScroll = function (xScrollPosition, yScrollPosition) {
            // Search for data-parallax div's, for each is a scrolling animation that we must build
            let parallaxContainers = document.querySelectorAll('[data-parallax]'), animations = [];
            // define a function for seeing if an element is visible and within our viewport:
            let isInViewport = function (element) {
                let bounding = element.getBoundingClientRect();
                //console.log( '', 'Bounding rect: ', bounding, 'Is in view: ', (  bounding.top >= 0 && bounding.top <= window.innerHeight ) || ( bounding.bottom <= ( window.innerHeight || document.documentElement.clientHeight ) && bounding.bottom >= 0 ), '' );
                return ((bounding.top >= 0 && bounding.top <= window.innerHeight) || (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.bottom >= 0)
                //bounding.left >= 0 &&
                //( bounding.bottom <= ( window.innerHeight || document.documentElement.clientHeight ) ) &&
                //( bounding.right <= ( window.innerWidth || document.documentElement.clientWidth ) )
                );
            };
            let setTranslate = function (xPosition, yPosition, element) {
                element.style.transform = "translate3d(" + xPosition + ", " + yPosition + "px, 0)";
            };
            _.each(parallaxContainers, function (groupIndex, layerGroup) {
                // Check if the group is visible
                let runParallax = isInViewport(layerGroup);
                // If so run parallax operations
                if (runParallax) {
                    //console.log( '', 'In runParallax', 'Group: ' + groupIndex + ', group: ', layerGroup, '' );
                    let parallaxLayers = layerGroup.querySelectorAll('[data-parallax-animation]');
                    _.each(
                    // For each layer
                    parallaxLayers, function (layerIndex, layer) {
                        //console.log( '', 'In b4 setTranslate:', 'Layer: ' + layerIndex + ', element: ', layer, '' );
                        // Each layer will have special properties to determine how to handle animating it,
                        // but for now we'll only use 2 layer parallax groups where the back scrolls more
                        // slowly than the front...
                        setTranslate(0, yScrollPosition * layer.getAttribute('data-animation-scroll'), layer);
                        //console.log( 'Transformed layer' + layerIndex + ' by (' + groupIndex + ' + .' + layer.getAttribute( 'data-animation-scroll' ) + ') * ' + yScrollPosition );
                    });
                }
                //else
                //{
                //console.log( 'Not in view port' );
                //}
            });
            // Set window.ticking to false at the end of an animation segment:
            window.ticking = false;
        };
        let runParallaxScrollEventHandler = function () {
            let xScrollPosition = window.scrollX;
            let yScrollPosition = window.scrollY;
            if (!window.ticking) {
                window.requestAnimationFrame(function () {
                    // Set window.ticking to true in order to safe-guard animations:
                    window.ticking = true;
                    checkForParallaxOnScroll(xScrollPosition, yScrollPosition);
                });
            }
        };
        // This is one way we can store event handlers to reference for removal:
        //( window as any ).kux.eventHandlers.runParallaxScrollEventHandler = runParallaxScrollEventHandler;
        window.addEventListener("scroll", runParallaxScrollEventHandler, false);
    }
    /**
     * Method to initialize and implement scrollToTarget feature
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.0
     */
    scrollToTarget() {
        let thisInstance = this, scrollToTriggers = document.querySelectorAll('[data-scroll-to-target]');
        _.each(scrollToTriggers, function (key, trigger) {
            let scrollToTargetTriggerClickEventHandler = function () {
                thisInstance = this;
                let targetId = trigger.getAttribute('data-scroll-to-target'), target = document.getElementById(targetId);
                if (targetId === 'top') {
                    thisInstance.scrollToTop();
                }
                else {
                    // this should work now
                    target.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            };
            trigger.onclick = scrollToTargetTriggerClickEventHandler.bind(thisInstance);
        });
    }
    /**
     * Method to intialize and impliment the MDC floating label control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    floatingLabels() {
        // Get a handle to all floating labels
        let floatingLabelElements = document.querySelectorAll('[data-label="floating"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.floatingLabels = window.kwaeri.floatingLabels || [];
        _.each(floatingLabelElements, function (key, floatingLabel) {
            window.kwaeri.floatingLabels.push(new MDCFloatingLabel(floatingLabel));
        });
    }
    /**
     * Method to intialize and impliment the MDC form field control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    formFields() {
        // Get a handle to all floating labels
        let formFieldElements = document.querySelectorAll('[data-field="form"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.formFields = window.kwaeri.formFields || [];
        _.each(formFieldElements, function (key, formField) {
            window.kwaeri.formFields.push(new MDCFormField(formField));
            // If the formfield has a data-for attribute, there's a dependent child that
            // needs to be set as the formfield's input:
            if (formField.hasAttribute("data-for")) {
                // Grab a list of children (there will probably only be one)
                let dependent = document.querySelectorAll(`[name=${formField.getAttribute("name")}`);
                // For each of the children:
                _.each(dependent, (key, dep) => {
                    // Set the child as the input for the formfield:
                    /*
                        The checkbox will work without JavaScript, but you can enhance it with a ripple
                        interaction effect by instantiating MDCCheckbox on the mdc-checkbox element. To
                        activate the ripple effect upon interacting with the label, you must also inst-
                        antiate MDCFormField on the mdc-form-field element and set the MDCCheckbox ins-
                        tance as its input.
                    */
                    formField.input = dep;
                });
            }
        });
    }
    /**
     * Method to intialize and impliment the MDC text field (input) control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    textFields() {
        // Get a handle to all floating labels
        let textFieldElements = document.querySelectorAll('[data-field="text"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.textFields = window.kwaeri.textFields || [];
        _.each(textFieldElements, function (key, textField) {
            window.kwaeri.textFields.push(new MDCTextField(textField));
        });
    }
    /**
     * Method to intialize and impliment the MDC icon toggle control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    iconToggles() {
        // Get a handle to all floating labels
        let iconToggleElements = document.querySelectorAll('[data-field="icon-toggle"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.iconToggles = window.kwaeri.iconToggles || [];
        _.each(iconToggleElements, function (key, iconToggle) {
            window.kwaeri.iconToggles.push(new MDCIconButtonToggle(iconToggle));
            //MDCIconToggle.attachTo( iconToggle );
        });
    }
    /**
     * Method to intialize and impliment the MDC line ripple control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    lineRipples() {
        // Get a handle to all floating labels
        let lineRippleElements = document.querySelectorAll('[data-field="line-ripple"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.lineRipples = window.kwaeri.lineRipples || [];
        _.each(lineRippleElements, function (key, lineRippleElement) {
            window.kwaeri.lineRipples.push(new MDCLineRipple(lineRippleElement));
        });
    }
    /**
     * Method to intialize and impliment the MDC notched outline control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    notchedOutlines() {
        // Get a handle to all floating labels
        let notchedOutlineElements = document.querySelectorAll('.mdc-notched-outline');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.notchedOutlines = window.kwaeri.notchedOutlines || [];
        _.each(notchedOutlineElements, function (key, notchedOutlineElement) {
            window.kwaeri.notchedOutlines.push(new MDCNotchedOutline(notchedOutlineElement));
        });
    }
    /**
     * Method to intialize and impliment the MDC radio control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    radios() {
        // Get a handle to all floating labels
        let radioButtonElements = document.querySelectorAll('[data-field="radio"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.radioButtons = [];
        _.each(radioButtonElements, function (key, radioButtonElement) {
            window.kwaeri.lineRipples.push(new MDCRadio(radioButtonElement));
        });
    }
    /**
     * Method to intialize and impliment the MDC checkbox control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    checkboxes() {
        // Get a handle to all floating labels
        let checkboxElements = document.querySelectorAll('[data-field="checkbox"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.checkboxButtons = [];
        _.each(checkboxElements, function (key, checkboxElement) {
            window.kwaeri.checkboxButtons.push(new MDCCheckbox(checkboxElement));
        });
    }
    /**
     * Method to intialize and impliment the MDC select control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    selects() {
        // Get a handle to all select list controls
        let selectListElements = document.querySelectorAll('[data-field="select"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.selectLists = window.kwaeri.selectLists || [];
        _.each(selectListElements, function (key, selectList) {
            // The MDC function returns the required object:
            const select = new MDCSelect(selectList);
            // Build our event handler to use the returned object?:
            //let selectListChangeEventHandler = function( e )
            //{
            //    console.log( `Selected option at index ${select.selectedIndex} with value ${select.value}` );
            //};
            // Invoke the listen function of the returned object:
            //select.listen( 'MDCSelect:change', selectListChangeEventHandler );
            // Store the returned object (not the select 'element'):
            window.kwaeri.selectLists.push(select);
        });
    }
    /**
     * Method to intialize and impliment the MDC slider control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    sliders() {
        // Get a handle to all floating labels
        let sliderElements = document.querySelectorAll('[data-field="slider"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.sliders = [];
        _.each(sliderElements, function (key, slider) {
            window.kwaeri.sliders.push(new MDCSlider(slider));
            let sliderChangeEventHandler = function (e) {
                //console.log( 'Rounded Value changed to ' + Math.round( ( 100 * slider.getAttribute( 'aria-valuenow' ) ) ) / 100 );
            };
            slider.addEventListener('MDCSlider:change', sliderChangeEventHandler, false);
        });
    }
    /**
     * Method to intialize and impliment the MDC switch control
     *
     * @param void
     *
     * @return void
     *
     * @since 0.1.3
     */
    switches() {
        // Get a handle to all floating labels
        let switchElements = document.querySelectorAll('[data-field="switch"]');
        // Prepare a container to hold all the active MDComponent instances we instantiate by type
        window.kwaeri = window.kwaeri || {};
        window.kwaeri.swiches = [];
        _.each(switchElements, function (key, switchElement) {
            window.kwaeri.sliders.push(new MDCSlider(switchElement));
            let switchChangeEventHandler = function (e) {
                //console.log( 'Value changed to ' + slider.value );
            };
            //slider.addEventListener( 'MDCSlider:change', sliderChangeEventHandler, false );
        });
    }
}
